import { get } from './client.js'
import { fetchItemFromLocalStorage, saveItemsToLocalStorage } from '../helpers/localStore'

export function getGlobalUserId() {
  return fetchItemFromLocalStorage("uid", "session")
}

export function getUser(id) {
  return get('/users/' + id)
}

export function getOrganization(organization_id) {
  return get('/organizations/' + organization_id);
}

export function getOwnUser() {
  const id = getGlobalUserId()

  return get('/users/' + id)
}

export const setOwnUser = (userObj) => {
  const userName = userObj.first_name + " " + userObj.last_name

  saveItemsToLocalStorage({
    name: userName,
    email: userObj.email_address,
  }, "user-details")
}
